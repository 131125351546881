/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import '@trumpf-xguide/xguide/styles';
@import "@trumpf/ux.ui/dist/ux-ui/ux-ui.css";
@import "@trumpf/ux.ui-community/dist/ux-ui-community/ux-ui-community.css";

html,
body {
  font-family: 'OpenSans', sans-serif;
  overflow: hidden;

  // style guide overrides
  min-width: unset !important;
}

.ux-cookie-layer-switch__button {
  min-width: 50px;
}

.scrollbar-view-container {
  border-top: none !important;
}

.interactive-element {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.dialog-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 650px) {
  ng-component .wizard {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    max-width: 100vw !important;
    box-sizing: border-box !important;
    margin: 0 !important;
  }
}

.simple-text-tooltip {
  width: 300px;
  font-size: 14px;
}

.non-draggable {
  -webkit-user-drag: none;
}
xui-button {
  height: 56px!important;
}
